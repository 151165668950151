import React from 'react';
// @mui
import { Stack, Typography, useTheme } from '@mui/material';

const InfoIcon = ({
	icon,
	title,
	description,
}: {
	icon: string;
	title: string;
	description: string;
}) => {
	const theme = useTheme();

	return (
		<Stack
			direction="column"
			spacing={2}
			sx={{
				width: '80%',
				padding: theme.spacing(6),
				[theme.breakpoints.down('lg')]: {
					padding: theme.spacing(2),
					width: '100%',
				},
			}}
		>
			<div
				style={{
					backgroundColor: theme.palette.primary.main,
					borderRadius: `50%`,
					padding: theme.spacing(1),
					margin: `0 auto`,
				}}
			>
				<img
					src={icon}
					alt="icon"
					style={{
						padding: theme.spacing(1),
						width: `90px`,
						height: `90px`,
					}}
				/>
			</div>
			<Typography
				sx={{
					fontWeight: 700,
					fontSize: '20px',
				}}
				color="secondary"
				align="center"
			>
				{title}
			</Typography>
			<Typography
				sx={{
					fontSize: '16px',
					fontWeight: 400,
				}}
				variant="body2"
				align="center"
			>
				{description}
			</Typography>
		</Stack>
	);
};

export default InfoIcon;
