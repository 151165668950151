import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
// @mui
import { Stack, Typography, useTheme } from '@mui/material';

// @utils
import { t } from 'hohm-i18n';
import { Analytics } from 'hohm-utilities';

const Footer = () => {
	const theme = useTheme();
	const footerLinks = t('pages.signup.sections.footer.links');
	const router = useRouter();

	const linksWithUTM = useMemo(
		() =>
			footerLinks.map((linkSet) =>
				linkSet.map((linkItem) => ({
					label: linkItem.label,
					link: Analytics.getLinkWithUTMAndCampaignParams(
						linkItem.link,
						router.query
					),
				}))
			),
		[footerLinks, router.query]
	);

	return (
		<Stack
			direction={{ xs: 'column', sm: 'row' }}
			spacing={2}
			sx={{
				backgroundColor: theme.palette.secondary.main,
				padding: `${theme.spacing(4)} ${theme.spacing(0)}`,
				[theme.breakpoints.down('md')]: {
					padding: theme.spacing(4),
				},
			}}
			justifyContent="space-evenly"
		>
			{linksWithUTM.map((column, index) => (
				<Stack direction="column" spacing={2} key={index}>
					{column.map((item) => (
						<a
							href={item.link}
							key={item.label}
							target="_blank"
							rel="noreferrer"
						>
							<Typography variant="body1" color="white">
								<b>{item.label}</b>
							</Typography>
						</a>
					))}
				</Stack>
			))}
		</Stack>
	);
};

export default Footer;
