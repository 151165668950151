import React from 'react';
import { useRouter } from 'next/router';
// @app
import { feathersClient, queries } from '@app/client';
// @types
import { CampaignTypes } from 'hohm-types';
// @components
import { Analytics } from 'hohm-utilities';
import BatterySolutions from './BatterySolutions';
import HybridSolutions from './HybridSolutions';
// @utils
import { getValidKits, useGetMatchingKits } from '../../utils';

export interface IKitInfoProps {
	id: string;
	subscriptionCost: number | undefined;
	name: string;
	description: string;
	monthlySavings: string;
	icon: string;
	inverter: {
		number: number;
		size: number;
		name: string;
	};
	panel?: {
		number: number;
		size: number;
		name: string;
		generation: string;
	};
	battery?: {
		number: number;
		size: number;
		name: string;
	};
	totalCost: number;
	tags?: (
		| 'PREMIUM'
		| 'VALUE'
		| 'SHINE'
		| 'GLOW'
		| 'FLASH PLUS'
		| 'SPARK PLUS'
		| 'FLASH'
		| 'SPARK'
	)[];
}

const Solutions = () => {
	const router = useRouter();

	const { mutate: getMatchingKits } = useGetMatchingKits(feathersClient);

	const [campaignKits, setCampaignKits] = React.useState<IKitInfoProps[]>([]);
	const [fetchPublicKits, setFetchPublicKits] = React.useState(false);
	const [publicKits, setPublicKits] = React.useState<IKitInfoProps[]>([]);

	const campaignDetails = Analytics.getCampaign(router.query);

	const campaignToken = campaignDetails?.campaign || 'public';

	const { data: campaigns } =
		queries.useGetEntities<CampaignTypes.ICampaignRead>('campaigns', {
			token: ['public', campaignToken],
			$loadEager: ['approvedPaymentOptions'],
		});

	const publicCampaignId = React.useMemo(() => {
		const publicCampaign = campaigns?.data.find(
			(campaign) => campaign.token === 'public'
		);

		return publicCampaign ? publicCampaign.id : null;
	}, [campaigns]);

	const campaignId = React.useMemo(() => {
		const selectedCampaign = campaigns?.data.find(
			(campaign) => campaign.token === campaignToken
		);

		return selectedCampaign ? selectedCampaign.id : null;
	}, [campaigns, campaignToken]);

	React.useEffect(() => {
		if (!campaignId) {
			setFetchPublicKits(true);
		}

		if (campaignId) {
			getMatchingKits(campaignId, {
				onSuccess: (data) => {
					const newData = getValidKits({
						data,
					}) as IKitInfoProps[];

					const validKits = newData?.filter((kit) =>
						kit.tags?.includes('VALUE')
					) as IKitInfoProps[];

					setCampaignKits(validKits);
					setFetchPublicKits(true);
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campaignId]);

	React.useEffect(() => {
		if (campaignId === publicCampaignId) {
			return;
		}

		if (publicCampaignId && fetchPublicKits === true) {
			getMatchingKits(publicCampaignId, {
				onSuccess: (data) => {
					const newData = getValidKits({
						data,
					}) as IKitInfoProps[];

					const validKits = newData?.filter((kit) =>
						kit.tags?.includes('VALUE')
					) as IKitInfoProps[];

					setPublicKits(validKits);
					setFetchPublicKits(false);
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [publicCampaignId, fetchPublicKits]);

	const allKits = React.useMemo(() => {
		return campaignKits.length > 0 ? campaignKits : publicKits;
	}, [campaignKits, publicKits]);

	const hybridKits = React.useMemo(() => {
		const filteredKits =
			allKits
				.filter((kit) => kit.panel && kit.panel.number > 0)
				.sort(
					(a, b) =>
						Number(a.subscriptionCost) - Number(b.subscriptionCost)
				) || [];

		return filteredKits;
	}, [allKits]);

	const batteryOnlyKits = React.useMemo(() => {
		const filteredKits =
			allKits
				.filter((kit) => kit.panel?.number === 0 || !kit.panel)
				.sort(
					(a, b) =>
						Number(a.subscriptionCost) - Number(b.subscriptionCost)
				) || [];

		return filteredKits;
	}, [allKits]);

	return (
		<>
			<BatterySolutions batteryOnlyKits={batteryOnlyKits} />
			<HybridSolutions hybridKits={hybridKits} />
		</>
	);
};

export default Solutions;
