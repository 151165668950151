import React, { useState } from 'react';
// @mui
import {
	Stack,
	Typography,
	useTheme,
	CircularProgress,
	Divider,
	useMediaQuery,
} from '@mui/material';
// types
import { t } from 'hohm-i18n';
// @local
import KitComponent from '../../functionalComponents/KitComponent';
import DotCarousel from '../../functionalComponents/DotCarousel';
import { IKitInfoProps } from '.';

const BatterySection = ({
	batteryOnlyKits,
}: {
	batteryOnlyKits: IKitInfoProps[];
}) => {
	const theme = useTheme();
	const [activeKit, setActiveKit] = useState<number>(0);

	const title = t('pages.signup.sections.batterySolutions.title');
	const showFinePrint = t('pages.signup.showHomeLoanFinePrint');

	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		batteryOnlyKits.length > 0 && (
			<Stack
				direction="column"
				spacing={6}
				sx={{
					backgroundColor: theme.palette.background.neutral,
					padding: theme.spacing(8),
				}}
			>
				<Typography
					align="center"
					sx={{
						fontSize: '48px',
						fontWeight: 500,
					}}
				>
					{title}
				</Typography>

				<Divider variant="inset" />

				<Typography
					sx={{
						fontSize: '24px',
						fontWeight: 600,
					}}
					variant="body1"
					align="center"
				>
					Battery only Solutions
				</Typography>

				<Stack
					direction={{ xs: 'column', md: 'row' }}
					spacing={{ xs: 0, md: 6 }}
					alignItems="center"
					justifyContent="center"
				>
					{batteryOnlyKits == null ? (
						<Stack
							justifyContent="center"
							alignContent="center"
							alignItems="center"
							direction="column"
						>
							<CircularProgress
								color="secondary"
								size="200px"
								sx={{
									mt: '50%',
									justifyContent: 'center',
									alignContent: 'center',
									alignItems: 'center',
									height: '100vh',
								}}
							/>
						</Stack>
					) : !isMobile ? (
						batteryOnlyKits.map((kit) => (
							<KitComponent
								key={kit.id}
								kitName={kit.name}
								kitDescription={kit.description}
								icon={kit.icon}
								kitComponents={{
									battery: kit.battery || {
										name: '',
										size: '',
										number: 0,
									},
									inverter: kit.inverter,
								}}
								kitCostMonth={kit.subscriptionCost || 0}
								kitCostCash={kit.totalCost}
								spendRange={kit.monthlySavings}
							/>
						))
					) : (
						<>
							<KitComponent
								key={batteryOnlyKits[activeKit].id}
								kitName={batteryOnlyKits[activeKit].name}
								kitDescription={
									batteryOnlyKits[activeKit].description
								}
								icon={batteryOnlyKits[activeKit].icon}
								kitComponents={{
									battery: batteryOnlyKits[activeKit]
										.battery || {
										name: '',
										size: '',
										number: 0,
									},
									inverter:
										batteryOnlyKits[activeKit].inverter,
								}}
								kitCostMonth={
									batteryOnlyKits[activeKit]
										.subscriptionCost || 0
								}
								kitCostCash={
									batteryOnlyKits[activeKit].totalCost
								}
								spendRange={
									batteryOnlyKits[activeKit].monthlySavings
								}
							/>
							<DotCarousel
								length={batteryOnlyKits.length}
								activeStep={activeKit}
								setActiveStep={setActiveKit}
							/>
						</>
					)}
				</Stack>

				{showFinePrint && (
					<Typography variant="caption" align="center">
						{`*System financed via a Home Loan facility over 20 years at an
							interest rate of 11.61%. Applicants must meet the bank's
							eligibility criteria.`}
					</Typography>
				)}
			</Stack>
		)
	);
};

export default BatterySection;
