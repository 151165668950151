import React from 'react';

import Header from './Header';
import Footer from './Footer';

const SignUpPage = ({ children }: { children: React.ReactElement }) => {
	return (
		<div>
			<Header />

			{children}

			<Footer />
		</div>
	);
};

export default SignUpPage;
