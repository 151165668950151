export const hybrid =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/kitImages/fullsystem.png';

export const battery =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/kitImages/battery.png';

export const inverterBattery =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/kitImages/battery&inverter.png';

export const backgroundImage =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/titleBackgroundImage.png';

// svg icons
export const checkListIcon =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/svg/checklist.svg';

export const crossIcon =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/svg/cross.svg';

export const googleIcon =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/svg/google.svg';

export const headphonesIcon =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/svg/headphones.svg';

export const hellopeterIcon =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/svg/hellopeter.svg';

export const starsIcon =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/svg/stars.svg';

export const toolsIcon =
	'https://spark-space.ams3.cdn.digitaloceanspaces.com/static-images/signUp/svg/tools.svg';
