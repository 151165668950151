import React from 'react';
// @mui
import { Stack, Typography, useTheme } from '@mui/material';

import { googleIcon, hellopeterIcon, starsIcon } from '../images';

interface ICustomerReviewProps {
	title: string;
	description: string;
	icon: string;
}

const GoogleLogo = () => {
	return (
		<img
			src={googleIcon}
			alt="Google"
			style={{
				width: '30%',
			}}
		/>
	);
};

const HelloPeterLogo = () => {
	return (
		<img
			src={hellopeterIcon}
			alt="HelloPeter"
			style={{
				width: '40%',
			}}
		/>
	);
};

const StarsLogo = () => {
	return <img src={starsIcon} alt="Stars" />;
};

const CustomerReview = ({ title, description, icon }: ICustomerReviewProps) => {
	const theme = useTheme();

	return (
		<Stack
			direction="column"
			spacing={4}
			sx={{
				padding: theme.spacing(2),
				width: '100%',
			}}
		>
			<Typography variant="h5">{title}</Typography>

			<Typography variant="body1">{description}</Typography>

			<Stack
				direction="row"
				spacing={1}
				justifyContent={{ xs: 'space-between', sm: 'left' }}
			>
				{icon === 'Google' ? <GoogleLogo /> : <HelloPeterLogo />}

				<StarsLogo />
			</Stack>
		</Stack>
	);
};

export default CustomerReview;
