import React from 'react';
// @mui
import { Stack, Typography, useTheme, Grid } from '@mui/material';

import { hooks } from 'hohm-ui';
import { headphonesIcon, checkListIcon, toolsIcon } from '../images';

import InfoIcon from '../functionalComponents/InfoIcon';

const whyUseData = [
	{
		icon: headphonesIcon,
		title: 'Step 1',
		description:
			'Fill in your requirements to get your proposal from an advisor',
	},
	{
		icon: checkListIcon,
		title: 'Step 2',
		description: `Select financing to suit your budget`,
	},
	{
		icon: toolsIcon,
		title: 'Step 3',
		description: `Schedule installation`,
	},
];

const EnergyIndependence = () => {
	const theme = useTheme();
	const { isMobile } = hooks.useDeviceType();

	return (
		<Stack
			direction="column"
			spacing={{ xs: 8, md: 2 }}
			sx={{
				backgroundColor: theme.palette.background.paper,
				padding: isMobile ? theme.spacing(4) : theme.spacing(8),
			}}
		>
			<Stack direction="column" spacing={2}>
				<Typography
					align="center"
					sx={{
						fontSize: '48px',
						fontWeight: 500,
					}}
				>
					Your energy independence
					<br />
					starts now.
				</Typography>

				<Typography
					align="center"
					sx={{
						fontSize: '24px',
						fontWeight: 500,
					}}
				>
					Easy as 1, 2, 3
				</Typography>
			</Stack>

			<Grid
				container
				direction="row"
				justifyContent="center"
				spacing={{ xs: 0, md: 6 }}
			>
				{whyUseData.map((item) => (
					<Grid
						item
						xs={12}
						md={4}
						lg={3}
						key={item.title}
						sx={{
							[theme.breakpoints.down('md')]: {
								marginBottom: '20px',
							},
						}}
					>
						<InfoIcon
							icon={item.icon}
							title={item.title}
							description={item.description}
						/>
					</Grid>
				))}
			</Grid>
		</Stack>
	);
};

export default EnergyIndependence;
