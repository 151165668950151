import React, { useState } from 'react';
// @mui
import {
	Stack,
	Typography,
	useTheme,
	CircularProgress,
	useMediaQuery,
} from '@mui/material';
import { t } from 'hohm-i18n';
// @local
import KitComponent from '../../functionalComponents/KitComponent';
import DotCarousel from '../../functionalComponents/DotCarousel';
import { IKitInfoProps } from '.';

const HybridSolutions = ({ hybridKits }: { hybridKits: IKitInfoProps[] }) => {
	const theme = useTheme();

	const [activeKit, setActiveKit] = useState<number>(0);

	const showFinePrint = t('pages.signup.showHomeLoanFinePrint');

	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	// only display on mobile if there is an active kit

	return (
		hybridKits.length > 0 && (
			<Stack
				direction="column"
				spacing={6}
				sx={{
					backgroundColor: theme.palette.background.paper,
					padding: theme.spacing(8),
				}}
			>
				<Typography
					sx={{
						fontSize: '24px',
						fontWeight: 600,
					}}
					align="center"
				>
					Solar & Battery Solutions
				</Typography>

				<Stack
					direction={{ xs: 'column', lg: 'row' }}
					spacing={{ xs: 0, md: 4 }}
					alignItems="center"
					justifyContent="center"
				>
					{hybridKits.length === 0 ? (
						<Stack
							justifyContent="center"
							alignContent="center"
							alignItems="center"
							direction="column"
						>
							<CircularProgress
								color="secondary"
								size="200px"
								sx={{
									mt: '50%',
									justifyContent: 'center',
									alignContent: 'center',
									alignItems: 'center',
									height: '100vh',
								}}
							/>
						</Stack>
					) : !isMobile ? (
						hybridKits.map((kit) => (
							<KitComponent
								key={kit.id}
								kitName={kit.name}
								kitDescription={kit.description}
								icon={kit.icon}
								kitComponents={{
									battery: kit.battery || {
										name: '',
										size: '',
										number: 0,
									},
									inverter: kit.inverter,
									panel: kit.panel,
								}}
								kitCostMonth={kit.subscriptionCost || 0}
								kitCostCash={kit.totalCost}
								spendRange={kit.monthlySavings}
							/>
						))
					) : (
						<>
							<KitComponent
								key={hybridKits[activeKit].id}
								kitName={hybridKits[activeKit].name}
								kitDescription={
									hybridKits[activeKit].description
								}
								icon={hybridKits[activeKit].icon}
								kitComponents={{
									battery: hybridKits[activeKit].battery || {
										name: '',
										size: '',
										number: 0,
									},
									inverter: hybridKits[activeKit].inverter,
									panel: hybridKits[activeKit].panel,
								}}
								kitCostMonth={
									hybridKits[activeKit].subscriptionCost || 0
								}
								kitCostCash={hybridKits[activeKit].totalCost}
								spendRange={
									hybridKits[activeKit].monthlySavings
								}
							/>
							<DotCarousel
								length={hybridKits.length}
								activeStep={activeKit}
								setActiveStep={setActiveKit}
							/>
						</>
					)}
				</Stack>

				{showFinePrint && (
					<Typography variant="caption" align="center">
						{`*System financed via a Home Loan facility over 20 years at an
				interest rate of 11.61%. Applicants must meet the bank's
				eligibility criteria.`}
					</Typography>
				)}
			</Stack>
		)
	);
};

export default HybridSolutions;
