import React from 'react';
import { useRouter } from 'next/navigation';
// @mui
import { Stack, Typography, useTheme, Button, Box } from '@mui/material';
import { t } from 'hohm-i18n';
import { Analytics } from 'hohm-utilities';
import { hooks } from 'hohm-ui';
import { backgroundImage } from '../images';

const TitleSection = () => {
	const theme = useTheme();
	let title = t('pages.signup.sections.titleSection.title');
	const { isMobile } = hooks.useDeviceType();
	const router = useRouter();

	const navigateSignup = () => {
		Analytics.track(Analytics.Events.AppGetStartedButtonClicked);
		router.push('/select-kit/?step=authenticate');
	};

	if (!title) {
		title = "From start to solar, we've got your back.";
	}

	return (
		<Box sx={{ position: 'relative' }}>
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					height: '100%',
					width: '100%',
					background: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,${isMobile ? '0.75' : '0.6'}) 100%);`,
				}}
			/>
			<Stack
				direction="column"
				spacing={10}
				alignItems="center"
				sx={{
					backgroundImage: `url(${backgroundImage})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundColor: theme.palette.primary.main,
					color: 'white',
					padding: theme.spacing(16),
					paddingBottom: theme.spacing(8),
					maxHeight: '90vh',
					[theme.breakpoints.down('md')]: {
						padding: `${theme.spacing(12)} ${theme.spacing(0)} ${theme.spacing(8)}`,
						height: 'auto',
					},
				}}
			>
				<Stack
					alignItems="center"
					sx={{
						[theme.breakpoints.up('md')]: {
							width: '50%',
						},
					}}
				>
					<Typography
						sx={{
							fontSize: '5.3rem',
							fontWeight: 400,
							[theme.breakpoints.down('lg')]: {
								fontSize: '3.8rem',
							},
							[theme.breakpoints.down('md')]: {
								fontSize: '48px',
								textAlign: 'center',
								width: '70%',
							},
							color: 'white',
							zIndex: 999,
						}}
					>
						<b>{title}</b>
					</Typography>
				</Stack>

				<Stack
					alignItems="center"
					sx={{
						[theme.breakpoints.up('md')]: {
							width: '50%',
						},
					}}
				>
					<Button
						variant="contained"
						color="primary"
						size="large"
						onClick={navigateSignup}
					>
						<Typography
							sx={{
								fontSize: isMobile ? 16 : 20,
								fontWeight: '600',
							}}
						>
							Get started
						</Typography>
					</Button>
				</Stack>
			</Stack>
		</Box>
	);
};

export default TitleSection;
