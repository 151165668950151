import { FeathersApplication } from '@feathersjs/feathers';
import { useMutation } from 'react-query';
import * as Sentry from '@sentry/nextjs';
import { EngineeredProposalTypes, CampaignConstantTypes } from 'hohm-types';
// @utilities
import { logger } from 'hohm-utilities';
// @images
import { hybrid, battery as batteryIcon, inverterBattery } from './images';

const message = 'Failed to get kits for campaign';

export const useGetMatchingKits = (feathersClient: FeathersApplication) => {
	return useMutation(
		(campaignIdParam: string) =>
			feathersClient
				.service('proposals/get-campaign-matching-kits')
				.find({
					query: {
						campaignId: campaignIdParam,
					},
				}),
		{
			onError: (error: unknown) => {
				if (error instanceof Error) {
					logger.error(message, error);

					Sentry.withScope((scope) => {
						scope.setExtra('action', message);
						scope.setTag('package', 'ip-module');
						Sentry.captureException(error);
					});
				}
			},
		}
	);
};
const getKitDetails = ({
	totalKw,
	systemType,
}: {
	totalKw: number;
	systemType: 'storage' | 'hybrid';
}) => {
	let systemInfo = {
		description: '',
		monthlySavings: '',
		icon: '',
	};

	const setStorageDetails = (size: number) => {
		systemInfo.description =
			size < 5
				? 'Your entry-level backup solution'
				: 'Your mid-sized backup solution';
		systemInfo.icon = size < 5 ? batteryIcon : inverterBattery;
	};

	const setHybridDetails = (size: number) => {
		if (size < 5) {
			systemInfo = {
				description: 'Your mid-sized backup solution',
				monthlySavings: 'R800pm - R1200pm',
				icon: hybrid,
			};
		} else if (size >= 5 && size < 8) {
			systemInfo = {
				description: 'Your mid-sized solar kit',
				monthlySavings: 'R1400pm - R1800pm',
				icon: hybrid,
			};
		} else if (size >= 8 && size < 12) {
			systemInfo = {
				description:
					'Solar energy for most of the day, and stored for much of the night',
				monthlySavings: 'R2000pm - R2800pm',
				icon: hybrid,
			};
		} else {
			systemInfo = {
				description:
					'Solar to meet all your high-consumption energy needs',
				monthlySavings: 'R3000pm - R3600pm',
				icon: hybrid,
			};
		}
	};

	if (systemType === 'storage') {
		setStorageDetails(totalKw);
	} else {
		setHybridDetails(totalKw);
	}

	return systemInfo;
};

export const getValidKits = ({
	data,
}: {
	data: EngineeredProposalTypes.IProposalPricing[];
}) => {
	return data.map((kit: EngineeredProposalTypes.IProposalPricing) => {
		const {
			productKit: {
				breakdown: {
					quantity,
					id,
					subscriptionCost,
					name,
					inverter,
					panel,
					battery,
					config: { limits },
					tags,
				},
			},
			totals: { final },
		} = kit;

		const { description, monthlySavings, icon } = getKitDetails({
			totalKw:
				Number(quantity.inverter) * (Number(inverter?.kwSize) || 0),
			systemType: quantity.panel > 0 ? 'hybrid' : 'storage',
		});

		return {
			id,
			subscriptionCost,
			name,
			description,
			monthlySavings,
			icon,
			inverter: {
				number: quantity.inverter,
				size: inverter.kwSize,
				name: inverter.brand,
			},
			panel: panel && {
				number: quantity.panel,
				size: panel.wattYield,
				name: panel.brand,
				generation: (
					((Number(limits.kwp.min) / Number(panel.wattYield)) *
						1000 *
						Number(panel?.wattYield)) /
					1000
				).toFixed(2),
				minPanels: Math.floor(
					(Number(limits.kwp.min) * 1000) / panel.wattYield
				),
				maxPanels: Math.floor(
					(Number(limits.kwp.max) * 1000) / panel.wattYield
				),
			},
			battery: battery && {
				number: quantity.battery,
				size: battery.kwh,
				name: battery.brand,
				minBackup:
					Number(limits.battery.min || 0) * (battery.kwh * 0.8),
				maxBackup: Math.floor(
					Number(limits.battery.max || 0) * (battery.kwh * 0.8)
				),
			},
			totalCost: final,
			tags,
		};
	});
};

export const getLogoMaxHeight = (
	brandName: CampaignConstantTypes.TCampaignCodes
) => {
	switch (brandName.toLowerCase()) {
		case 'investec':
			return '60px';
		case 'clearscore':
			return '25px';
		default:
			return '40px';
	}
};
