import React from 'react';

import { t } from 'hohm-i18n';

import TitleSection from './sections/TitleSection';
import Solutions from './sections/solutions';
import WhyUse from './sections/WhyUse';
import EnergyIndependence from './sections/EnergyIndependence';
import FinanceOptions from './sections/FinanceOptions';
import Testimonials from './sections/Testimonials';

const ShortcutSignUpPage = () => {
	const showFinanceOptions = t('pages.signup.sections.showFinanceOptions');

	return (
		<div>
			<TitleSection />
			<Solutions />
			<WhyUse />
			<EnergyIndependence />
			{showFinanceOptions && <FinanceOptions />}
			<Testimonials />
		</div>
	);
};

export default ShortcutSignUpPage;
