import React, { useState } from 'react';
// @mui
import {
	Paper,
	Stack,
	Typography,
	Button,
	useTheme,
	Divider,
	Dialog,
} from '@mui/material';

import { HandleCurrency } from 'hohm-utilities';
import { HubSpotWidget } from 'hohm-ui';
import { t } from 'hohm-i18n';

interface IComponentItem {
	name: string;
	size: string | number;
	number: number;
	maxBackup?: number;
	generation?: string;
}

interface IKitComponent {
	battery: IComponentItem;
	inverter?: IComponentItem;
	panel?: IComponentItem;
}

const HubspotDialog = ({
	hubspotUrl,
	setHubspotUrl,
}: {
	hubspotUrl: string | null;
	setHubspotUrl: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
	return (
		<Dialog
			open={hubspotUrl !== null}
			onClose={() => setHubspotUrl(null)}
			fullWidth
			maxWidth="md"
		>
			<Button
				onClick={() => setHubspotUrl(null)}
				sx={{
					position: 'absolute',
					top: '16px',
					right: '16px',
				}}
			>
				Close
			</Button>

			{hubspotUrl && (
				<HubSpotWidget.HubSpotIFrameInitializer
					hubspotMeetingUrl={hubspotUrl}
				/>
			)}
		</Dialog>
	);
};

const KitComponent = ({
	kitName,
	kitDescription,
	icon,
	kitComponents,
	kitCostMonth,
	kitCostCash,
	spendRange,
}: {
	kitName: string;
	kitDescription: string;
	icon: string;
	kitComponents: IKitComponent;
	kitCostMonth: number | null;
	kitCostCash: number;
	spendRange?: string;
}) => {
	const [hubspotUrl, setHubspotUrl] = useState<string | null>(null);
	const theme = useTheme();
	const buyOnly = t('buyOnly');
	const subscriptionOnly = t('subscriptionOnly');

	const formatKitComponents = (components: IKitComponent) => {
		let returnString = '';

		// inverter
		if (components?.inverter) {
			returnString += `Inverter | ${components.inverter.name.replace('(Glint)', '')} - ${components.inverter.size} kW\n`;
		}

		// battery
		returnString += `${components.battery.number} x Battery | ${components.battery.name} - ${components.battery.size} kWh\n`;

		// panel
		if (components?.panel && components.panel.number > 0) {
			returnString += `${components.panel.number} x ${components.panel.size}W ${components.panel.name} Pannels\n`;
		}

		return returnString;
	};

	const showSubscriptionCost =
		(subscriptionOnly || (!buyOnly && !subscriptionOnly)) && kitCostMonth;
	const showBuyCost = buyOnly || (!buyOnly && !subscriptionOnly);

	return (
		<Paper
			elevation={0}
			sx={{
				border: `1px solid ${theme.palette.grey[300]}`,
				width: '300px',
				pt: 2,
				// on hover add box shadow
				'&:hover': {
					boxShadow: '0 9px 20px 13px rgba(8, 15, 51, .09);',
				},
			}}
		>
			<HubspotDialog
				hubspotUrl={hubspotUrl}
				setHubspotUrl={setHubspotUrl}
			/>

			<Stack
				spacing={2}
				direction="column"
				sx={{
					backgroundColor: 'white',
					m: '20px',
				}}
			>
				<Typography variant="h4" color="primary" align="center">
					{kitName}
				</Typography>

				<Typography variant="body2" align="center" height="50px">
					{kitDescription}
				</Typography>

				<Stack direction="row" justifyContent="center">
					<img
						src={icon}
						alt="battery and inverter"
						style={{
							maxWidth: '100%',
							height: '110px',
							margin: `${theme.spacing(2)} 0`,
						}}
					/>
				</Stack>

				<Typography
					variant="body2"
					align="center"
					sx={{
						whiteSpace: 'pre-line',
					}}
				>
					{formatKitComponents(kitComponents)}
				</Typography>

				{spendRange && (
					<Stack
						direction="row"
						justifyContent="center"
						sx={{
							backgroundColor: theme.palette.background.neutral,
							padding: '16px',
							borderRadius: '10px',
						}}
					>
						<Typography variant="body2" align="center">
							<b>
								If your electricity spend is
								<br />
								{spendRange}
							</b>
						</Typography>
					</Stack>
				)}
			</Stack>

			<Stack
				spacing={2}
				direction="column"
				sx={{
					backgroundColor: theme.palette.background.neutral,
					padding: '32px',
				}}
			>
				{showSubscriptionCost && (
					<>
						<Typography
							variant="body2"
							align="center"
							color="primary"
						>
							<b>Monthly Price</b>
						</Typography>

						<Typography variant="h4" align="center">
							<b>
								From{' '}
								{HandleCurrency.formatCurrency({
									value: kitCostMonth,
									currency: 'zar',
									format: '0,0',
								})}
								<sup>*</sup>
							</b>
						</Typography>

						<Divider variant="inset" textAlign="center" />
					</>
				)}

				{showBuyCost && (
					<>
						<Typography
							variant="body2"
							align="center"
							color="primary"
						>
							<b>Cash Price</b>
						</Typography>

						<Typography variant="h4" align="center">
							<b>
								{HandleCurrency.formatCurrency({
									value: kitCostCash,
									currency: 'zar',
									format: '0,0',
								})}
							</b>
						</Typography>
					</>
				)}
			</Stack>
		</Paper>
	);
};

export default KitComponent;
