import React, { useState } from 'react';
// @mui
import {
	Stack,
	Typography,
	useTheme,
	Grid,
	useMediaQuery,
} from '@mui/material';

import { t } from 'hohm-i18n';

import CustomerReview from '../functionalComponents/CustomerReview';
import DotCarousel from '../functionalComponents/DotCarousel';

const testimonialData = [
	{
		title: 'Great project management from start to completion',
		description:
			'Great project management from start to completion. Thank you HOHM for the excellent experience. I must compliment the installer; our installation was done by EDGE SOLAR. They are very professional; they were on time and the installation was done very neatly. We used MC to finance the solar solution. The only issue we had was with the insurance for the Solar system, but once that was sorted out, everything was smooth sailing.',
		icon: 'Google',
	},
	{
		title: 'We really had a good experience with HOHM',
		description:
			'We really had a good experience with HOHM. They did everything from their side. HOHM kept us informed on a daily basis. Thank you, Thendo from HOHM, for the continues updates. Great project management from start to completion.',
		icon: 'HelloPeter',
	},
	{
		title: 'We so often forget to complement people',
		description:
			'People are so quick to complain about things, We so often forget to complement people I have had a very pleasant experience with Hohm Energy, installing a solar system at my home in Pretoria. A special thanks to the following people: Anne and her team Tumi, Lethabo, Neil, Dylon and his Technical team. The service I received was of a very standard The installation was very neat and tidy. Thank you all.',
		icon: 'Google',
	},
];

const Testimonials = () => {
	const [activeReview, setActiveReview] = useState<number>(0);
	const theme = useTheme();
	const brand = t('brand');
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Stack
			direction="column"
			spacing={2}
			sx={{
				backgroundColor:
					brand.name === 'Investec'
						? theme.palette.background.neutral
						: theme.palette.background.paper,
				padding: theme.spacing(8),
			}}
		>
			<Typography
				align="center"
				sx={{
					fontSize: '48px',
					fontWeight: 500,
					lineHeight: '56.02px',
				}}
			>
				What our customers
				<br />
				have to say
			</Typography>

			{!isMobile ? (
				<Grid
					container
					direction="row"
					justifyContent="space-evenly"
					spacing={6}
				>
					{testimonialData.map((item) => (
						<Grid item xs={12} lg={3} key={item.title}>
							<CustomerReview
								icon={item.icon}
								title={item.title}
								description={item.description}
							/>
						</Grid>
					))}
				</Grid>
			) : (
				<>
					<CustomerReview
						icon={testimonialData[activeReview].icon}
						title={testimonialData[activeReview].title}
						description={testimonialData[activeReview].description}
					/>
					<DotCarousel
						length={testimonialData.length}
						activeStep={activeReview}
						setActiveStep={setActiveReview}
					/>
				</>
			)}
		</Stack>
	);
};

export default Testimonials;
