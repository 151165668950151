import React from 'react';
// @mui
import {
	Stack,
	Typography,
	useTheme,
	Grid,
	useMediaQuery,
} from '@mui/material';

import { t } from 'hohm-i18n';

const BatterySection = () => {
	const theme = useTheme();
	const financePartners = t('financePartners');

	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Stack
			direction="column"
			sx={{
				backgroundColor: theme.palette.background.neutral,
				padding: theme.spacing(8),
			}}
		>
			<Typography
				align="center"
				sx={{
					fontSize: '48px',
					fontWeight: 500,
					lineHeight: '56.02px',
				}}
				mb={10}
			>
				The broadest range of solar finance
				{!isMobile ? <br /> : ' '}
				options all in one place
			</Typography>

			<Grid
				container
				direction="row"
				justifyContent="center"
				spacing={{ xs: 0, md: 6 }}
			>
				{financePartners.map((item) => (
					<Grid
						item
						key={item.name}
						sx={{
							[theme.breakpoints.down('md')]: {
								margin: `${theme.spacing(2)} auto`,
								width: '225px',
							},
						}}
						justifyContent="center"
						alignContent="center"
						alignItems="center"
					>
						<img
							src={item.logo}
							alt={item.name}
							style={{
								width: item.name === 'FNB' ? '100px' : '225px',
							}}
						/>
					</Grid>
				))}
			</Grid>
		</Stack>
	);
};

export default BatterySection;
