import React, { ReactElement } from 'react';
// @components
import ShortcutSignUpPage from '@app/general-components/ShortcutSignupPage';
import SignUpLayout from '@app/general-components/ShortcutSignupPage/layoutComponents/SignUpLayout';

const Page = () => {
	return <ShortcutSignUpPage />;
};

Page.getLayout = function getLayout(page: ReactElement) {
	return <SignUpLayout>{page}</SignUpLayout>;
};

export default Page;
