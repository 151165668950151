import React from 'react';
// @mui
import { Stack, Typography, useTheme, Grid, styled } from '@mui/material';
// @i8n
import { t } from 'hohm-i18n';
// @types
import { CampaignConstantTypes } from 'hohm-types';
// @local
import { crossIcon } from '../images';
import InfoIcon from '../functionalComponents/InfoIcon';
import { getLogoMaxHeight } from '../utils';

const StyledLogo = styled('img')(() => ({
	opacity: 1,
}));

const nonCobrand = ['Hohm Energy'];

const WhyUse = () => {
	const theme = useTheme();
	const brand = t('brand');
	const partner = t('partner');
	const logoMaxHeight = getLogoMaxHeight(
		brand.name as CampaignConstantTypes.TCampaignCodes
	);
	const isCoBrand = !nonCobrand.includes(brand.name);
	const whyUseData = t('pages.signup.whyUseData');
	const hideSolarFinance = t('hideSolarFinance');

	return (
		<Stack
			direction="column"
			sx={{
				backgroundColor: theme.palette.background.neutral,
				padding: theme.spacing(6, 0),
				[theme.breakpoints.down('md')]: {
					padding: theme.spacing(2, 0),
				},
			}}
		>
			<Typography
				align="center"
				sx={{
					fontSize: '48px',
					fontWeight: 500,
				}}
			>
				Why use
			</Typography>

			{isCoBrand ? (
				<Grid container justifyContent="center" spacing={0}>
					<Stack
						direction="row"
						spacing={{ xs: 1, lg: 2 }}
						sx={{
							margin: theme.spacing(1, 0),
						}}
					>
						<StyledLogo
							sx={{
								maxWidth: '100%',
								maxHeight: logoMaxHeight,
								[theme.breakpoints.down('lg')]: {
									display: 'block',
									margin: '0 auto',
								},
							}}
							src={partner.logo}
							alt={`${partner.name} logo`}
						/>

						{!hideSolarFinance ? (
							<img src={crossIcon} alt="cross icon" />
						) : null}

						<StyledLogo
							sx={{
								maxWidth: '100%',
								maxHeight: logoMaxHeight,
								[theme.breakpoints.down('lg')]: {
									display: 'block',
									margin: '0 auto',
								},
							}}
							src={brand.logo}
							alt={`${brand.name} logo`}
						/>
					</Stack>
				</Grid>
			) : (
				<Stack direction="row" justifyContent="center">
					<StyledLogo
						sx={{
							maxHeight: '100px',
							maxWidth: '100%',
							[theme.breakpoints.down('lg')]: {
								display: 'block',
								margin: '0 auto',
							},
						}}
						src={brand.logo}
						alt={`${brand.name} logo`}
					/>
				</Stack>
			)}

			<Grid
				container
				direction="row"
				justifyContent="center"
				spacing={{ xs: 0, lg: 6 }}
				sx={{
					padding: theme.spacing(0, 8),
				}}
			>
				{whyUseData.map((item) => (
					<Grid
						item
						xs={12}
						lg={4}
						key={item.title}
						sx={{
							[theme.breakpoints.down('lg')]: {
								marginBottom: '20px',
							},
						}}
					>
						<InfoIcon
							icon={item.icon}
							title={item.title}
							description={item.description}
						/>
					</Grid>
				))}
			</Grid>
		</Stack>
	);
};

export default WhyUse;
