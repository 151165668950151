import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const DotsMobileStepper = ({
	length,
	activeStep,
	setActiveStep,
	color,
}: {
	length: number;
	activeStep: number;
	setActiveStep: React.Dispatch<React.SetStateAction<number>>;
	color?: string;
}) => {
	const theme = useTheme();

	const handleNext = () => {
		setActiveStep(() => activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(() => activeStep - 1);
	};

	return (
		<MobileStepper
			variant="dots"
			steps={length}
			position="static"
			activeStep={activeStep}
			sx={{
				width: '100%',
				flexGrow: 1,
				backgroundColor: color || 'transparent',
			}}
			nextButton={
				<Button
					size="small"
					onClick={handleNext}
					disabled={activeStep === length - 1}
				>
					Next
					{theme.direction === 'rtl' ? (
						<KeyboardArrowLeft />
					) : (
						<KeyboardArrowRight />
					)}
				</Button>
			}
			backButton={
				<Button
					size="small"
					onClick={handleBack}
					disabled={activeStep === 0}
				>
					{theme.direction === 'rtl' ? (
						<KeyboardArrowRight />
					) : (
						<KeyboardArrowLeft />
					)}
					Back
				</Button>
			}
		/>
	);
};

export default DotsMobileStepper;
