import React from 'react';
// @mui
import {
	Grid,
	AppBar,
	Box,
	Toolbar,
	styled,
	useTheme,
	Stack,
} from '@mui/material';
// @i18n
import { t } from 'hohm-i18n';
// @types
import { CampaignConstantTypes } from 'hohm-types';
// @local
import { getLogoMaxHeight } from '../utils';
import { crossIcon } from '../images';

const StyledLogo = styled('img')(({ theme }) => ({
	marginTop: 10,
	opacity: 1,
	[theme.breakpoints.down('sm')]: {
		marginTop: 20,
	},
}));

const nonCobrand = ['Hohm Energy'];

const Header = () => {
	const brand = t('brand');
	const partner = t('partner');
	const hideBrandLogo = t('packages.hohmPdf.hideBrandLogo');
	const logoMaxHeight = getLogoMaxHeight(
		brand.name as CampaignConstantTypes.TCampaignCodes
	);

	const theme = useTheme();
	const isCoBrand = !nonCobrand.includes(brand.name);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="fixed" color="inherit" elevation={0}>
				<Toolbar>
					<Grid
						container
						justifyContent="space-between"
						alignItems="center"
						paddingTop={1}
						paddingBottom={1}
					>
						<Grid item md={2} lg={1} sm={0} xs={0} />

						<Grid item md={10} lg={11} sm={12} xs={12}>
							{isCoBrand && !hideBrandLogo ? (
								<Stack
									direction="row"
									spacing={{ xs: 1, md: 2 }}
									sx={{
										margin: theme.spacing(1, 0),
									}}
								>
									<StyledLogo
										sx={{
											maxWidth: '100%',
											maxHeight: logoMaxHeight,
											[theme.breakpoints.down('md')]: {
												display: 'block',
												margin: '0 auto',
											},
										}}
										src={partner.logo}
										alt={`${partner.name} logo`}
									/>

									<img src={crossIcon} alt="cross icon" />

									<StyledLogo
										sx={{
											maxWidth: '100%',
											maxHeight: logoMaxHeight,
											[theme.breakpoints.down('md')]: {
												display: 'block',
												margin: '0 auto',
											},
										}}
										src={brand.logo}
										alt={`${brand.name} logo`}
									/>
								</Stack>
							) : (
								<StyledLogo
									sx={{
										maxWidth: '100%',
										maxHeight: '40px',
										[theme.breakpoints.down('md')]: {
											// center logo on mobile
											display: 'block',
											margin: '0 auto',
										},
									}}
									src={brand.logo}
									alt={`${brand.name} logo`}
								/>
							)}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default Header;
